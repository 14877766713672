a, .btn-link {
    color: #999;
    transition: color .12s ease-out 0s;
    &:hover {
        text-decoration: none;
        color: #145485;
    }
}
.dropdown-menu li > a:hover {
    background: transparent;
    color: #145485;
}

p {
    font-size: 16px;
    line-height: 1.8em;
}

#list-view, #grid-view {display: none;}

#menu {
    background: transparent;
    border: none;
    .nav > li {
        > a {
            font-size: 18px;
            font-weight: 400;
            text-shadow: none;
            color: #000;
            text-transform: uppercase;
            text-align: center;
        }
        > a:hover, &.open > a {
            background: transparent;
            color: #145485;
        }
        &:first-child > a {
            padding-left: 0;
        }
        &:last-child > a {
            padding-right: 0;
        }
    }
     
    #category {
        text-shadow: none;
        color: #000;
    }

    .dropdown-menu li > a:hover {
        background: transparent;
        color: #145485;
    }
   .see-all:hover,.see-all:focus {
        background: transparent;
        color: #145485;
   }
   @media (min-width: 767px) {
       .navbar-collapse {padding: 0;}
       .nav {
           display: flex;
           flex-wrap: wrap;
           justify-content: center;
           width: 100%;
       }
   }
   @media (min-width: 1200px) {
       .nav {
           flex-wrap: nowrap;
           justify-content: space-between;
       }
   }
}

.html-block {
    text-align: justify;
    .title {
        font-weight: 600;
        text-align: center;
    }
}
.category-banners {
    .item {
        margin-bottom: 30px;
        position: relative;
        &:before {
            background-image: linear-gradient(180deg, rgba(255,255,255,0) 50%, #fff 100%);
            box-shadow: 0 0 0 1px rgba(51,51,51,0.1) inset;
            position: absolute;
            content: '';
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            pointer-events: none;
        }
        .image {
            overflow: hidden;
            img {
                max-width: 100%;
                transition: transform .3s ease 0s;
            }
        }
        .title {
            position: absolute;
            left: 0; right: 0;
            bottom: 20px;
            color: #000;
            font-size: 22px;
            font-weight: 600;
            text-transform: uppercase;
            text-align: center;
            transition: transform .3s ease 0s;
            z-index: 2;
        }
        &:hover {
            .image {
                img {transform: scale(1.1);}
            }
            .title {transform: translateY(-8px) translateZ(0);}
        }
    }
}

.bottom-menu {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    margin-top: 40px;
    margin-bottom: 20px;
    li {
        width: 150px;
        padding-bottom: 20px;
        a {
            display: block;
            max-width: 100%;
            padding: 0 10px;
            line-height: 1.2;
            text-align: center;
            word-wrap: break-word;
            font-size: 16px;
            .svg-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 24px;
                margin-bottom: 5px;
            }
            &:hover {color: #145485;}
        }
    }
}

.product-thumb {
    border: none;
    position: relative;
    overflow: inherit;
    .image a:hover {
        opacity: 1;
    }
    .image {
        img {
            transition: transform .3s ease 0s;
            width: 100%;
        }
    }
    &:hover {
        .image {
            img {transform: scale(1.1);}
        }
    }
    .caption {
        min-height: auto;
        text-align: center;
        p:not(.price) {display: none;}
    }
    .button-group {
        background: transparent;
        border: none;
        button {
            display: none;
            position: absolute;
            right: 0;
            top: 0;
            &:last-child {
                right: 40px;
            }
        }
        button:first-child {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 30px;
            width: 110px;
            color: #000;
            border: 1px solid #ccc;
            background-color: #fff;
            font-size: 14px;
            text-transform: none;
            line-height: normal;
            &:hover {
                border-color: #b3b2b2;
                background-color: #f2f2f2;
                box-shadow: 0 1px 2px rgba(0,0,0,0.15);
            }
        }
    }
    &:hover {
        button {display: block;}
        .image a {opacity: 0.8;}
    }
}

.ocfilter {
    border: none;
    .panel-heading {
        background: transparent;
        color: #000;
        font-size: 18px;
        font-weight: 700;
        border: none;
    }
    .ocfilter-option {
        .ocf-option-name {
            font-size: 16px;
        }
        label {
            font-size: 16px;
            position: relative;
            padding-left: 24px;
            input {
                position: absolute;
                left: 0; top: 0;
                opacity: 0;
            }
            &.ocf-selected {color: #000;}
            &.ocf-selected:after {
                content: '';
                position: absolute;
                left: 1px;
                top: 7px;
                background-image: url("data:image/svg+xml;utf8,<svg width='27' height='23' viewBox='0 0 27 23' xmlns='http://www.w3.org/2000/svg'><path class='svg-line-check' d='M1.97 11.94L10.03 20 25.217 2' fill='none' fill-rule='evenodd' stroke='white' stroke-width='3' stroke-linecap='round'></path></svg>");
                width: 12px;
                height: 12px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 6px;
                width: 14px;
                height: 14px;
                border: 1px solid #abbbc8;
                border-radius: 3px;
                background-color: #f2f2f2;
                color: transparent;
                transition: background-color .1s ease 0s, border-color .1s ease 0s, color .1s ease 0s;
                will-change: transform, border-color, color;
            }
            &.ocf-selected:before {
                border-color: #1e7ec8;
                background-color: #1e7ec8;
            }

            small {
                position: absolute;
                right: 0;
                color: #999;
                background: none;
                font-size: 16px;
            }
        }
    }

    .selected-options {
        .ocfilter-option {background: transparent;}
        .btn-danger {
            display: inline-block;
            position: relative;
            border-radius: 2em;
            background: rgba(0,0,0,0.03);
            color: #333;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            padding: 5px 35px 5px 15px !important;
            text-shadow: none;
            border: none;
            margin-bottom: 10px;
            margin-right: 10px;
            i {
                position: absolute;
                right: 8px;
                top: 8px;
                color: #999;
            }
        }
    }
    .noUi-connect {background: #efefef;}
}



.alert-success {
    background-color: #efefef;
}
.form-control {
    height: 29px;
    &:focus {
        border-color: #efefef;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgb(239, 239, 239);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgb(239, 239, 239);
    }
}
.pagination>li>a, .pagination>li>span {
    color: #333;
    border-color: #efefef;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    color: #333;
    background-color: #efefef;
    border-color: #efefef;
}


.main-subscribe-from {
    padding-top: 83px;
    padding-bottom: 44px;
    background: #EED5D2;
    text-align: center;
    .title {
        font-size: 40px;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        padding-bottom: 20px;
    }
    .subtitle {
        font-size: 28px;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
    }
    .subscriptionForm {
        text-align: center;
        max-width: 535px;
        width: 100%;
        padding-top: 39px;
        padding-bottom: 20px;
        margin: 0 auto;
        .input-row {
            position: relative;
        }
         label {
            display: block;
            color: #666;
            font-size: 12px;
            padding: 7px 0;
            margin: 0;
            text-align: left;
        }
        .email-input {
            width: 100%;
            max-width: 351px;
            height: 53px;
            background: #fff;
            margin-right: 15px;
            font-size: 16px;
            padding: 0 10px;
            border: 1px solid #7f7f7f;
        }
        input[type=submit] {
            transition: all .3s linear;
            display: inline-block;
            background: #000;
            border: 1px solid #000;
            color: #fff;
            font-size: 16px;
            text-align: center;
            text-transform: uppercase;
            white-space: nowrap;
            max-width: 160px;
            width: 100%;
            padding: 14px 15px 15px;
        }
        .js-error {
            position: absolute;
            left: 70px;
            bottom: auto;
            top: 6px;
            color: red;
            display: none;
        }
    }
}

.page-part-share {
    width: 100%;
    z-index: 2;
    position: relative;
    .content-container {
        position: relative;
        height: 100%;
        width: 100%;
        padding-top: 100px;
    }
    .caption {
        margin-bottom: 56px;
        font-size: 14px;
        text-align: center;
    }
    .instagram-link {
        display: block;
        position: relative;
        font-size: 16px;
        color: #333;
        strong {
            display: block;
            font-size: 28px;
        }
        &:after {
            content: '';
            position: absolute;
            left: 0; right: 0;
            margin: auto;
            top: -50px;
            background-image: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 56.693 56.693' height='56.693px' id='Layer_1' version='1.1' viewBox='0 0 56.693 56.693' width='56.693px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g><polygon points='38.512,24.392 38.512,20.445 38.512,19.855 37.922,19.857 33.975,19.871 33.99,24.407  '/><path d='M28.225,34.868c2.598,0,4.713-2.113,4.713-4.711c0-1.025-0.334-1.975-0.893-2.748c-0.855-1.186-2.248-1.965-3.82-1.965   s-2.963,0.779-3.82,1.965c-0.559,0.773-0.891,1.723-0.891,2.748C23.514,32.755,25.627,34.868,28.225,34.868z'/><path d='M28.348,5.158c-13.6,0-24.625,11.023-24.625,24.623c0,13.6,11.025,24.625,24.625,24.625   c13.598,0,24.623-11.025,24.623-24.625C52.971,16.181,41.945,5.158,28.348,5.158z M41.611,27.409V38.37   c0,2.852-2.322,5.172-5.174,5.172H20.012c-2.853,0-5.173-2.32-5.173-5.172V27.409v-5.464c0-2.853,2.32-5.173,5.173-5.173h16.425   c2.852,0,5.174,2.32,5.174,5.173V27.409z'/><path d='M35.545,30.157c0,4.035-3.283,7.32-7.32,7.32s-7.318-3.285-7.318-7.32c0-0.973,0.193-1.898,0.537-2.748h-3.996V38.37   c0,1.414,1.15,2.564,2.564,2.564h16.425c1.414,0,2.564-1.15,2.564-2.564V27.409h-3.998C35.35,28.259,35.545,29.185,35.545,30.157z'/></g></svg>");
            width: 47px;
            height: 47px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}
.home-page {

    .carousel-0 {
        margin: 0;
        border: none;
        box-shadow: none;
        .swiper-slide {
            position: relative;
            overflow: hidden;
            a {
                display: block;
                // height: 300px;
                width: 100%;
                transition: transform .3s linear;
                &:hover {
                    transform: scale(1.18);
                }
            }
        }
        .swiper-button-next {right: 2px;}
        .swiper-button-prev {left: 2px;}
    }

}

#cart {
    margin-top: 10px;
    text-align: right;
    max-width: 58px;
    .cart-btn {
        border: 1px solid #e6e5e5;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
        background-color: #fff;
        border-radius: 50%;
        padding: 10px;
        display: inline-block;
        position: relative;
        transition: background-color .2s ease 0s, box-shadow .2s ease 0s;
        &:hover {
            background-color: #f5f5f5;
            box-shadow: 0 5px 20px 0 rgba(0,0,0,0.15);
        }
    }
    #cart-count {
        position: absolute;
        right: -4px;
        top: -4px;
        background: #efefef;
        border-radius: 50%;
        min-width: 1.4em;
        height: 1.4em;
        padding: 2px 0;
    }
}

.home-fetured, .home-video-fetured, .video-category {
    .product-thumb {
        position: relative;
        .caption {
            text-align: left;
            position: absolute;
            left: 10px;
            bottom: 20px;
            display: none;
            h4 {
                font-size: 30px;
                a {color: #000;}
            }
            .price {
                font-size: 24px;
                line-height: 1;
            }
        }
        &:hover .caption {display: block;}
    }
}


.header-ico-btns {
    text-align: right;
    .icon-inst {
        vertical-align: middle;
        border: 1px solid #e6e5e5;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
        background-color: #fff;
        border-radius: 50%;
        padding: 12px 13px 8px;
        margin-top: 10px;
        margin-right: 10px;
        display: inline-block;
        position: relative;
        transition: background-color .2s ease 0s, box-shadow .2s ease 0s;
        &:hover {
            background-color: #f5f5f5;
            box-shadow: 0 5px 20px 0 rgba(0,0,0,0.15);
        }
    }
}